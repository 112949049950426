<template>
  <v-dialog
      v-model="getEmailViewer.show"
      width="40%"
      @click:outside="closeDialog('Close')">
    <v-card class="pa-5 containerBorder">
      <v-row
          justify="center"
          no-gutters
          class="fill-height">
        <v-col cols="10">
          <v-text-field
              placeholder="username@domain.com"
              outlined
              :rules="emailRules"
              v-model="emailAddress"
              class="ml-3 text-h5">
          </v-text-field>
        </v-col>
        <v-col cols="2" align-self="start">
          <v-btn
              x-large
              color="primary"
              class=" ml-4"
              @click="closeDialog('Send')">
            SEND
          </v-btn>
        </v-col>
        <v-col cols="12" class="">
          <SimpleKeyboard
              keyboardClass="email-keyboard"
              @onChange="onChange"
              @onKeyPress="onKeyPress"
              :input="emailAddress">
          </SimpleKeyboard>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import SimpleKeyboard from '@/components/SimpleKeyboard'
import {mapGetters} from "vuex";

export default {
  name: "emailViewer",
  data() {
    return {
      emailAddress: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      emailText: '',
      pdfUrl: '',
      pdfName: ''
    }
  },
  components: {
    SimpleKeyboard
  },
  computed: {
    ...mapGetters(['getEmailViewer'])
  },
  methods: {
    onChange(input) {
      this.emailAddress = input;
    },
    onKeyPress(button) {
      if (button === "{bksp}") {
        this.emailAddress = this.emailAddress.slice(0, -1)
      }
    },
    closeDialog(action) {
      if (action === 'Send') {
        switch (this.getEmailViewer.pdfType.toLowerCase()) {
          case 'form':
            this.emailText = 'Following is the link to the ' + '<strong>' + this.getEmailViewer.pdfName + '</strong>'+ ' form you requested in PDF format.'
            this.pdfUrl = this.getEmailViewer.pdfLink
            this.pdfName = this.getEmailViewer.pdfName
            break;
          case 'packet':
            this.emailText = 'Following is the link to the ' + '<strong>' + this.getEmailViewer.pdfName + '</strong>' + ' packet you requested in PDF format.'
            this.pdfUrl = this.getEmailViewer.pdfLink
            this.pdfName = this.getEmailViewer.pdfName
            break;
          case 'guide':
            this.emailText = 'Following is the link to the ' + '<strong>' + this.getEmailViewer.pdfName + '</strong>' + ' SRL guide you requested in PDF format.'
            this.pdfUrl = this.getEmailViewer.pdfLink
            this.pdfName = this.getEmailViewer.pdfName
            break;
          default:
            break;
        }
        this.$store.dispatch('sendEmail', {
          address: this.emailAddress,
          subject: 'New Mexico Court Forms',
          content: 'Hello,' +
              '<br>' +
              '<br>' + this.emailText +
              '<br>' +
              '<br>' + this.pdfUrl +
              '<br>' +
              '<br> Thank you,' +
              '<br><strong>Team Clara</strong>'
        })
            .then(response => {
              console.log(response)
              this.$store.commit('closeEmailViewer')
              this.$store.dispatch('avatarSpeak', 'Got it! you will receive the email shortly.')
              this.emailAddress = '';
              // Touch usage
              console.log('Email', this.pdfName)
              this.$store.commit('setTouchRequest', {
                module: 'Email Viewer',
                action: 'Email PDF',
                response: this.pdfName,
                timeStamp: new Date(),
                requestType: 'Email'
              })
            }, error => {
              console.log(error)
              this.$store.dispatch('avatarSpeak', 'Oops! There is some problem with email service. Try again later.')
              this.emailAddress = '';
            })
      } else if (action === 'Close') {
        this.$store.commit('closeEmailViewer')
      }
    }
  }
}
</script>

<style scoped>

</style>
