<template>
  <v-dialog
    v-model="getOptionsViewer.show"
    @click:outside="$store.commit('closeOptionsViewer')"
    width="67%"
    persistent
  >
    <v-card class="pa-5">
      <div style="position: absolute; align-self: end; left: 0; top: 0">
        <v-img
          @click="actionOnTap()"
          class="talk-button mt-3 ml-3"
          contain
          width="90px"
          :src="require('../../assets/' + talkButtonSrc)"
        >
        </v-img>
      </div>
      <v-card-title class="justify-center text-h4 pb-7 font-weight-bold">
        {{ getOptionsViewer.dialogHeader }}
      </v-card-title>
      <div style="position: absolute; align-self: end; right: 0; top: 0">
        <v-btn icon @click="closeDialog()" class="mr-5 mt-3">
          <v-icon color="red" x-large>mdi-window-close</v-icon>
        </v-btn>
      </div>
      <v-card-text align="center" class="pa-0">
        <v-card
          v-for="(item, index) in getOptionsViewer.dialogData"
          :key="index"
          class="my-1"
          width="85%"
          @click="viewElement(item)"
          color="primary"
        >
          <v-card-text class="white--text text-h5">
            <span v-if="typeof item === 'object'">
              {{ item.displayText }}
            </span>
            <span v-else>
              {{ item }}
            </span>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "optionsViewer",
  data() {
    return {
      talkButtonSrc: "talkButton.png",
    };
  },
  computed: {
    ...mapGetters([
      "getOptionsViewer",
      "getIsSpeaking",
      "speechRecognitionStatus",
      "getQnaStatus",
      "getResetKioskStatus",
    ]),
  },
  methods: {
    viewElement(input) {
      // Touch Usage
      this.$store.commit("setTouchRequest", {
        module: "Options Viewer",
        action: input,
        response: "Action executed on touch",
        timeStamp: new Date(),
        requestType: "Touch",
      });
      this.$store.dispatch("requestQnA", input);
      this.$store.commit("closeOptionsViewer");
    },
    closeDialog() {
      this.$store.commit("closeOptionsViewer");
      this.$store.dispatch("kioskStillThere");
      window.stopSpeech();
    },
    actionOnTap() {
      console.log("Is Speaking", this.getResetKioskStatus);
      if (!this.getIsSpeaking) {
        if (this.getResetKioskStatus) {
          this.$store.commit("setSpeechRecognitionHandler", true);
        } else {
          this.$store.commit("setSpeechRecognitionHandler", true);
        }
      } else {
        window.stopSpeech();
        this.talkButtonSrc = "talkButton.png";
      }
    },
  },
  watch: {
    getIsSpeaking(newVal) {
      if (newVal) {
        this.talkButtonSrc = "stopTalking.png";
      }
      if (!newVal && !this.speechRecognitionStatus) {
        this.talkButtonSrc = "talkButton.png";
      }
    },
    speechRecognitionStatus(newVal) {
      if (newVal) {
        this.talkButtonSrc = "talkButtonActive.webp";
      } else {
        this.talkButtonSrc = "talkButton.png";
      }
    },
  },
};
</script>

<style scoped>
.talk-button {
  filter: sepia(19%) saturate(697%) hue-rotate(294deg) brightness(95%)
    contrast(350%);
}
</style>
