import {
    floorEnQuery,
    allFormEnQuery,
    mapEnQuery,
    faqEnQuery,
    faqCategoryEnQuery,
    formCategoryEnQuery,
    formPacketEnQuery,
    homepageEnQuery,
    allFormSecondEnQuery
} from '@/graphql/englishQuery.js';

export default {
    state: {
        homepageEn: [],
        floorsEn: [],
        mapsEn: new Map(),
        formsEn: new Map(),
        faqsEn: [],
        faqCategoryEn: [],
        formCategoriesEn: [],
        formPacketsEn: new Map(),
    },
    getters: {
        getHomepageEn: state => state.homepageEn,
        getFloorsEn: state => state.floorsEn,
        getMapsEn: state => state.mapsEn,
        getFaqsEn: state => state.faqsEn,
        getFaqCategoriesEn: state => state.faqCategoryEn,
        getFormsEn: state => state.formsEn,
        getFormCategoryEn: state => state.formCategoriesEn,
        getFormPacketsEn: state => state.formPacketsEn
    },
    mutations: {
        setHomepageEn(state, page) {
            state.homepageEn.push(page)
        },
        setFloorsEn(state, floor) {
            state.floorsEn.push(floor)
        },
        setMapsEn(state, map) {
            state.mapsEn.set(map.mapName.toLowerCase(), map)
        },
        setFormsEn(state, form) {
            state.formsEn.set(form.formName.toLowerCase(), form)
        },
        setFaqsEn(state, faq) {
            state.faqsEn.push(faq)
        },
        setFaqCategoryEn(state, faqCategories) {
            state.faqCategoryEn = faqCategories
        },
        setFormCategoriesEn(state, categories) {
            state.formCategoriesEn = categories
        },
        setFormPacketsEn(state, packet) {
            state.formPacketsEn.set(packet.packetName.toLowerCase(), packet)
        }
    },
    actions: {
        fetchHomePageEn({ commit, dispatch, getters }) {
            return new Promise((resolve, reject) => {
                getters.getApolloClient.query({
                    query: homepageEnQuery
                }).then(items => {
                    items.data.homepages.forEach(page => {
                        dispatch('saveInCache', page.menuIcon.url)
                            .then(response => {
                                page.menuIcon.url = response
                                commit('setHomepageEn', page);
                            })
                    })
                    resolve('Homepage fetched successfully.')

                }, error => {
                    reject(error.message)
                })

            })
        },
        // Fetch Floor from strapi
        fetchFloorsEn({ commit, dispatch, getters }) {
            return new Promise((resolve, reject) => {
                getters.getApolloClient.query({
                    query: floorEnQuery
                }).then(floors => {
                    floors.data.floors.forEach(floor => {
                        dispatch('saveInCache', floor.floorImage.url)
                            .then(response => {
                                floor.floorImage.url = response;
                                commit('setFloorsEn', floor)
                            })
                    })
                    resolve('Floor data fetched successfully.')
                }, error => {
                    reject(error.message)
                })
            })
        },

        // Fetching Maps from Strapi
        fetchMapsEn({ commit, getters, dispatch }) {
            return new Promise((resolve, reject) => {
                getters.getApolloClient.query({
                    query: mapEnQuery
                }).then(maps => {
                    maps.data.maps.forEach(map => {
                        dispatch('saveInCache', map.mapImage.url)
                            .then(response => {
                                commit('setMapsEn', {
                                    mapName: map.mapName,
                                    mapFloor: map.mapFloor,
                                    mapImage: response,
                                    speech: map.speech
                                })
                            })
                    })
                    resolve('Map data fetched successfully.')
                }, error => {
                    reject(error.message)
                })
            })
        },

        // Fetch Forms from strapi
        fetchFormsEn({ commit, dispatch, getters }) {
            return new Promise((resolve, reject) => {
                getters.getApolloClient.query({
                    query: allFormEnQuery
                }).then(forms => {
                    forms.data.allForms.forEach(form => {
                        dispatch('saveInCache', form.formPdf.url)
                            .then(response => {
                                commit('setFormsEn', {
                                    formName: form.formName,
                                    formCategory: form.formCategory,
                                    displayIcon: form.displayIcon,
                                    formPdf: response,
                                    pdfUrl: form.pdfUrl
                                })
                            })
                    })
                    resolve('Form data fetched successfully.')
                }, error => {
                    reject(error.message)
                })


                getters.getApolloClient.query({
                    query: allFormSecondEnQuery
                }).then(forms => {
                    forms.data.allForms.forEach(form => {
                        dispatch('saveInCache', form.formPdf.url)
                            .then(response => {
                                commit('setFormsEn', {
                                    formName: form.formName,
                                    formCategory: form.formCategory,
                                    displayIcon: form.displayIcon,
                                    formPdf: response,
                                    pdfUrl: form.pdfUrl
                                })
                            })
                    })
                    resolve('Form data fetched successfully.')
                }, error => {
                    reject(error.message)
                })
            })
        },

        fetchFaqsEn({ commit, getters, dispatch }) {
            return new Promise((resolve, reject) => {
                getters.getApolloClient.query({
                    query: faqEnQuery
                }).then(faqs => {
                    //commit('setFaqs', faqs.data.faqs);
                    console.log('Faqs Structured: ', faqs.data.faqs)
                    faqs.data.faqs.forEach(faq => {
                        if (faq.faqType[0].__typename === "ComponentFaqDisplayExpansionPanel") {
                            let data = {
                                question: faq.faqType[0].question,
                                answer: faq.faqType[0].answer.replace(/\n/g, '<br />'),
                                category: faq.category,
                                displayType: 'panel'
                            }
                            commit('setFaqsEn', data)
                        } else {
                            dispatch('saveInCache', faq.faqType[0].guide.url)
                                .then(response => {
                                    let data = {
                                        guideName: faq.faqType[0].guideName,
                                        guideLink: faq.faqType[0].guideLink,
                                        guideUrl: response,
                                        category: faq.category,
                                        displayType: 'button'
                                    }
                                    commit('setFaqsEn', data)
                                })
                        }
                    })
                    resolve('FAQs fetched successfully.')
                }, error => {
                    reject(error.message)
                })
            })
        },

        fetchFaqCategoryEn({ commit, getters }) {
            return new Promise((response, reject) => {
                getters.getApolloClient.query({
                    query: faqCategoryEnQuery
                }).then(faqCategory => {
                    commit('setFaqCategoryEn', faqCategory.data.faqCategories)
                    response('FAQ categories fetched successfully')
                }, error => {
                    reject(error.message)
                })
            })
        },

        fetchFormCategoriesEn({ commit, getters }) {
            return new Promise((response, reject) => {
                getters.getApolloClient.query({
                    query: formCategoryEnQuery
                }).then(categories => {
                    commit('setFormCategoriesEn', categories.data.formCategories)
                    response('Form categories fetched successfully.')
                }, error => {
                    reject(error.message)
                })
            })
        },

        fetchFormPacketsEn({ commit, dispatch, getters }) {
            return new Promise((response, reject) => {
                getters.getApolloClient.query({
                    query: formPacketEnQuery
                }).then(packets => {
                    packets.data.formPackets.forEach(packet => {
                        let files = [];
                        packet.packetFiles.forEach(file => {
                            dispatch('saveInCache', file.url)
                                .then(response => {
                                    files.push(response)
                                })
                        })
                        commit('setFormPacketsEn', {
                            packetName: packet.packetName,
                            packetDesc: packet.packetDesc,
                            packetLink: packet.packetLink,
                            packetFiles: files
                        })
                    })
                    response('Form packets fetched successfully.')
                }, error => {
                    reject(error.message)
                })
            })
        },
    }
}
