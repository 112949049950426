import gql from 'graphql-tag'

export const homepageEnQuery = gql`
query homeQuery {
  homepages {
    id
    speech    
    menuName     
    pageType{
      ... on ComponentHomepageNavigation{
        __typename
        pathName
        pageHeader
      }
      ... on ComponentHomepageDialogBox{
        __typename
        type
        media {
          id
          url
        }
        textList      
      }
    }
    menuIcon {
      id
      url
    }
  }
}
`

export const mapEnQuery = gql`
query mapQuery {
  maps{
    id
    mapName
    mapFloor
    speech
    mapImage {
      id
      url
    }
  }
}
`

export const floorEnQuery = gql`
query floorQuery {
  floors (sort: "floorNumber"){ 
    id
    floorName
    floorNumber
    speech
    floorImage {
      id
      url
    }
  }
}
`
export const faqEnQuery = gql`
query faqQuery {
  faqs {
    id
    category
    faqType{
      ... on ComponentFaqDisplayExpansionPanel{
        question
        answer
      }
      ... on ComponentFaqDisplayButton{
        guideName
        guideLink
        guide {
          id
          url
        }
      }
    }
  }
}
`

export const faqCategoryEnQuery = gql`
query faqCategoryQuery {
  faqCategories (sort: "displayOrder") {
    id
    categoryName
    displayType
  }
}
`

export const formCategoryEnQuery = gql`
query formCategoryQuery {
  formCategories {
    id
    categoryName
    displayType
    categoryType
  }
}
`

export const allFormEnQuery = gql`
query allFormQuery {
  allForms(start: 0) {
    id
    formName
    displayIcon
    formCategory
    pdfUrl
    formPdf{
      id
      url
    }
  }
}
`

export const allFormSecondEnQuery = gql`
query allFormQuery {
  allForms(start: 100) {
    id
    formName
    displayIcon
    formCategory
    pdfUrl
    formPdf{
      id
      url
    }
  }
}
`

export const formPacketEnQuery = gql`
query formPacketQuery {
    formPackets {
        id
        packetName
        packetDesc
        packetLink
        packetFiles{
            id
            url
        }
    }
}
`
