<template>
  <v-dialog
      v-model="getImageViewer.show"
      width="55%"
      @click:outside="$store.commit('closeImageViewer')">
    <v-card>
      <v-card-text class="d-flex justify-end" style="overflow-y: hidden">
        <v-progress-circular
            class="mt-5"
            @click="$store.commit('closeImageViewer'); window.stopSpeech();"
            :rotate="180"
            :size="65"
            :width="7"
            :value="closePercentage"
            color="error">
          <v-icon large color="error">
            mdi-close
          </v-icon>
        </v-progress-circular>
      </v-card-text>
      <v-img :src="getImageViewer.url"></v-img>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: "imageViewer",
  data(){
    return{
      closePercentage: 0,
      timeout: 0,
      intervalObj: 0
    }
  },
  computed: {
    ...mapGetters(['getImageViewer', 'getIsSpeaking'])
  },
  watch:{
    getIsSpeaking(newVal){
      if(!newVal){
        clearTimeout(this.timeout)
        this.intervalObj = setInterval(()=> {this.closePercentage += 20}, 1000)
        this.timeout = setTimeout(() => {
          clearInterval(this.intervalObj);
          this.closePercentage = 0;
          this.$store.commit('closeImageViewer')

        }, 5000)
      }else{
        this.closePercentage = 0
        clearInterval(this.intervalObj);
        clearTimeout(this.timeout)
      }
    }
  }
}
</script>

<style scoped>

</style>
