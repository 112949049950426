
module.exports = {
  firebase:{
    apiKey: "AIzaSyDELlTAE_BShT2XLIWJP56Yu4tU6L2IScw",
    authDomain: "connect-dashboard.firebaseapp.com",
    projectId: "connect-dashboard",
    storageBucket: "connect-dashboard.appspot.com",
    messagingSenderId: "1043807767610",
    appId: "1:1043807767610:web:759cc8164312f6a6d1f903",
    measurementId: "G-HD5TKQ24WG"
  },
  url:{
    clerkOffice: 'https://strapi.arsconnect.com/uploads/d1_002_clerks_office_98c7855ea0.gif'
  }
}

