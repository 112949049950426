import {
    floorEsQuery,
    allFormEsQuery,
    mapEsQuery,
    faqEsQuery,
    faqCategoryEsQuery,
    formCategoryEsQuery,
    formPacketEsQuery,
    homepageEsQuery,
    allFormSecondEsQuery
} from '@/graphql/spanishQuery.js';

export default {
    state: {
        homepageEs: [],
        floorsEs: [],
        mapsEs: new Map(),
        formsEs: new Map(),
        faqsEs: [],
        faqCategoryEs: [],
        formCategoriesEs: [],
        formPacketsEs: new Map(),
    },
    getters: {
        getHomepageEs: state => state.homepageEs,
        getFloorsEs: state => state.floorsEs,
        getMapsEs: state => state.mapsEs,
        getFaqsEs: state => state.faqsEs,
        getFaqCategoriesEs: state => state.faqCategoryEs,
        getFormsEs: state => state.formsEs,
        getFormCategoryEs: state => state.formCategoriesEs,
        getFormPacketsEs: state => state.formPacketsEs
    },
    mutations: {
        setHomepageEs(state, page) {
            state.homepageEs.push(page)
        },
        setFloorsEs(state, floor) {
            state.floorsEs.push(floor)
        },
        setMapsEs(state, map) {
            state.mapsEs.set(map.mapName.toLowerCase(), map)
        },
        setFormsEs(state, form) {
            console.log('Spanish Form: ', form)
            state.formsEs.set(form.formName.toLowerCase(), form)
        },
        setFaqsEs(state, faq) {
            state.faqsEs.push(faq)
        },
        setFaqCategoryEs(state, faqCategories) {
            state.faqCategoryEs = faqCategories
        },
        setFormCategoriesEs(state, categories) {
            state.formCategoriesEs = categories
        },
        setFormPacketsEs(state, packet) {
            state.formPacketsEs.set(packet.packetName.toLowerCase(), packet)
        }
    },
    actions: {
        fetchHomePageEs({ commit, dispatch, getters }) {
            return new Promise((resolve, reject) => {
                getters.getApolloClient.query({
                    query: homepageEsQuery
                }).then(items => {
                    items.data.homepages.forEach(page => {
                        dispatch('saveInCache', page.menuIcon.url)
                            .then(response => {
                                page.menuIcon.url = response
                                commit('setHomepageEs', page);
                            })
                    })
                    resolve('Homepage fetched successfully.')

                }, error => {
                    reject(error.message)
                })

            })
        },
        // Fetch Floor from strapi
        fetchFloorsEs({ commit, dispatch, getters }) {
            return new Promise((resolve, reject) => {
                getters.getApolloClient.query({
                    query: floorEsQuery
                }).then(floors => {
                    floors.data.floors.forEach(floor => {
                        dispatch('saveInCache', floor.floorImage.url)
                            .then(response => {
                                floor.floorImage.url = response;
                                commit('setFloorsEs', floor)
                            })
                    })
                    resolve('Floor data fetched successfully.')
                }, error => {
                    reject(error.message)
                })
            })
        },

        // Fetching Maps from Strapi
        fetchMapsEs({ commit, getters, dispatch }) {
            return new Promise((resolve, reject) => {
                getters.getApolloClient.query({
                    query: mapEsQuery
                }).then(maps => {
                    maps.data.maps.forEach(map => {
                        dispatch('saveInCache', map.mapImage.url)
                            .then(response => {
                                commit('setMapsEs', {
                                    mapName: map.mapName,
                                    mapFloor: map.mapFloor,
                                    mapImage: response,
                                    speech: map.speech
                                })
                            })
                    })
                    resolve('Map data fetched successfully.')
                }, error => {
                    reject(error.message)
                })
            })
        },

        // Fetch Forms from strapi
        fetchFormsEs({ commit, dispatch, getters }) {
            return new Promise((resolve, reject) => {
                getters.getApolloClient.query({
                    query: allFormEsQuery
                }).then(forms => {
                    forms.data.allForms.forEach(form => {
                        dispatch('saveInCache', form.formPdf.url)
                            .then(response => {
                                commit('setFormsEs', {
                                    formName: form.formName,
                                    formCategory: form.formCategory,
                                    displayIcon: form.displayIcon,
                                    formPdf: response,
                                    pdfUrl: form.pdfUrl
                                })
                            })
                    })
                    resolve('Form data fetched successfully.')
                }, error => {
                    reject(error.message)
                })


                getters.getApolloClient.query({
                    query: allFormSecondEsQuery
                }).then(forms => {
                    forms.data.allForms.forEach(form => {
                        dispatch('saveInCache', form.formPdf.url)
                            .then(response => {
                                commit('setFormsEs', {
                                    formName: form.formName,
                                    formCategory: form.formCategory,
                                    displayIcon: form.displayIcon,
                                    formPdf: response,
                                    pdfUrl: form.pdfUrl
                                })
                            })
                    })
                    resolve('Form data fetched successfully.')
                }, error => {
                    reject(error.message)
                })
            })
        },

        fetchFaqsEs({ commit, getters, dispatch }) {
            return new Promise((resolve, reject) => {
                getters.getApolloClient.query({
                    query: faqEsQuery
                }).then(faqs => {
                    //commit('setFaqs', faqs.data.faqs);
                    console.log('Faqs Structured: ', faqs.data.faqs)
                    faqs.data.faqs.forEach(faq => {
                        if (faq.faqType[0].__typename === "ComponentFaqDisplayExpansionPanel") {
                            let data = {
                                question: faq.faqType[0].question,
                                answer: faq.faqType[0].answer.replace(/\n/g, '<br />'),
                                category: faq.category,
                                displayType: 'panel'
                            }
                            commit('setFaqsEs', data)
                        } else {
                            dispatch('saveInCache', faq.faqType[0].guide.url)
                                .then(response => {
                                    let data = {
                                        guideName: faq.faqType[0].guideName,
                                        guideLink: faq.faqType[0].guideLink,
                                        guideUrl: response,
                                        category: faq.category,
                                        displayType: 'button'
                                    }
                                    commit('setFaqsEs', data)
                                })
                        }
                    })
                    resolve('FAQs fetched successfully.')
                }, error => {
                    reject(error.message)
                })
            })
        },

        fetchFaqCategoryEs({ commit, getters }) {
            return new Promise((response, reject) => {
                getters.getApolloClient.query({
                    query: faqCategoryEsQuery
                }).then(faqCategory => {
                    commit('setFaqCategoryEs', faqCategory.data.faqCategories)
                    response('FAQ categories fetched successfully')
                }, error => {
                    reject(error.message)
                })
            })
        },

        fetchFormCategoriesEs({ commit, getters }) {
            return new Promise((response, reject) => {
                getters.getApolloClient.query({
                    query: formCategoryEsQuery
                }).then(categories => {
                    commit('setFormCategoriesEs', categories.data.formCategories)
                    response('Form categories fetched successfully.')
                }, error => {
                    reject(error.message)
                })
            })
        },

        fetchFormPacketsEs({ commit, dispatch, getters }) {
            return new Promise((response, reject) => {
                getters.getApolloClient.query({
                    query: formPacketEsQuery
                }).then(packets => {
                    packets.data.formPackets.forEach(packet => {
                        let files = [];
                        packet.packetFiles.forEach(file => {
                            dispatch('saveInCache', file.url)
                                .then(response => {
                                    files.push(response)
                                })
                        })
                        commit('setFormPacketsEs', {
                            packetName: packet.packetName,
                            packetDesc: packet.packetDesc,
                            packetLink: packet.packetLink,
                            packetFiles: files
                        })
                    })
                    response('Form packets fetched successfully.')
                }, error => {
                    reject(error.message)
                })
            })
        },
    }
}
