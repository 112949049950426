import gql from 'graphql-tag'

export const homepageEsQuery = gql`
query homeQuery {
  homepages (sort: "displayPosition", locale: "es-US") {
    id
    speech    
    menuName     
    pageType{
      ... on ComponentHomepageNavigation{
        __typename
        pathName
        pageHeader
      }
      ... on ComponentHomepageDialogBox{
        __typename
        type
        media {
          id
          url
        }
        textList      
      }
    }
    menuIcon {
      id
      url
    }
  }
}
`

export const mapEsQuery = gql`
query mapQuery {
  maps (locale: "es-US") {
    id
    mapName
    mapFloor
    speech
    mapImage {
      id
      url
    }
  }
}
`

export const floorEsQuery = gql`
query floorQuery {
  floors (sort: "floorNumber", locale: "es-US"){ 
    id
    floorName
    floorNumber
    speech
    floorImage {
      id
      url
    }
  }
}
`
export const faqEsQuery = gql`
query faqQuery {
  faqs (locale: "es-US") {
    id
    category
    faqType{
      ... on ComponentFaqDisplayExpansionPanel{
        question
        answer
      }
      ... on ComponentFaqDisplayButton{
        guideName
        guideLink
        guide {
          id
          url
        }
      }
    }
  }
}
`

export const faqCategoryEsQuery = gql`
query faqCategoryQuery {
  faqCategories (locale: "es-US") {
    id
    categoryName
    displayType
  }
}
`

export const formCategoryEsQuery = gql`
query formCategoryQuery {
  formCategories (sort: "id", locale: "es-US") {
    id
    categoryName
    displayType
    categoryType
  }
}
`

export const allFormEsQuery = gql`
query allFormQuery {
  allForms(start: 0, locale: "es-US") {
    id
    formName
    displayIcon
    formCategory
    pdfUrl
    formPdf{
      id
      url
    }
  }
}
`

export const allFormSecondEsQuery = gql`
query allFormQuery {
  allForms(start: 100, locale: "es-US") {
    id
    formName
    displayIcon
    formCategory
    pdfUrl
    formPdf{
      id
      url
    }
  }
}
`

export const formPacketEsQuery = gql`
query formPacketQuery {
    formPackets (locale: "es-US") {
        id
        packetName
        packetDesc
        packetLink
        packetFiles{
            id
            url
        }
    }
}
`
