import * as fb from "@/firebase/index.js";
import axios from 'axios';
import { ApolloClient } from 'apollo-client';
import { createHttpLink, HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';



function today() {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0');
  var yyyy = today.getFullYear();
  return (mm + '-' + dd + '-' + yyyy);
}

export default {
  state: {
    dataLoaded: false,
    apolloClient: undefined,
    fileCache: null
  },
  getters: {
    getDataLoaded: state => state.dataLoaded,
    getApolloClient: state => state.apolloClient
  },
  mutations: {
    setDataLoaded(state, status) {
      state.dataLoaded = status;
    },
    setApolloClient(state, uri) {
      const fragmentMatcher = new IntrospectionFragmentMatcher({
        introspectionQueryResultData: {
          __schema: {
            types: [],
          },
        },
      });
      state.apolloClient = new ApolloClient({
        link: new HttpLink({ uri: uri }),
        cache: new InMemoryCache({ fragmentMatcher })
      })
    }
  },
  actions: {
    createApolloConnection({ commit }, uri) {
      return new Promise((resolve, reject) => {
        if (uri === '' || uri === null) reject('Empty or Invalid URI')
        try {
          commit('setApolloClient', uri)
          resolve('Apollo linked successfully');
        }
        catch (err) {
          reject(err.message);
        }
      })
    },

    // Pull data here
    fetchHomepage({ dispatch }) {
      return new Promise((response, reject) => {
        // Fetching HomePage data English Language
        dispatch('fetchHomePageEn').then(() => {
          // Fetching HomePage data Spanish Language
          dispatch('fetchHomePageEs').then(() => {
            response('Homepage fetched successfully.')
          })
        }, error => {
          reject(error.message)
        }, error => {
          reject(error.message)
        })
      })
    },
    fetchFaqCategory({ dispatch }) { 
      return new Promise((response, reject) => {
        // Fetching Faq Category data English Language
        dispatch('fetchFaqCategoryEn').then(() => {
          // Fetching Faq Category data Spanish Language
          dispatch('fetchFaqCategoryEs').then(() => {
            response('Faq Category fetched successfully.')
          })
        }, error => {
          reject(error.message)
        }, error => {
          reject(error.message)
        })
      })
    },
    fetchFaqs({ dispatch }) {
      return new Promise((response, reject) => {
        // Fetching Faq data English Language
        dispatch('fetchFaqsEn').then(() => {
          // Fetching Faq data Spanish Language
          dispatch('fetchFaqsEs').then(() => {
            response('Faqs fetched successfully.')
          })
        }, error => {
          reject(error.message)
        }, error => {
          reject(error.message)
        })
      })
     },
    fetchFloors({ dispatch }) {
      return new Promise((response, reject) => {
        // Fetching Floors data English Language
        dispatch('fetchFloorsEn').then(() => {
          // Fetching Floors data Spanish Language
          dispatch('fetchFloorsEs').then(() => {
            response('Floors fetched successfully.')
          })
        }, error => {
          reject(error.message)
        }, error => {
          reject(error.message)
        })
      })
     },
    fetchMaps({ dispatch }) {
      return new Promise((response, reject) => {
        // Fetching Maps data English Language
        dispatch('fetchMapsEn').then(() => {
          // Fetching Maps data Spanish Language
          dispatch('fetchMapsEs').then(() => {
            response('Maps fetched successfully.')
          })
        }, error => {
          reject(error.message)
        }, error => {
          reject(error.message)
        })
      })
     },
    fetchFormCategory({ dispatch }) {
      return new Promise((response, reject) => {
        // Fetching Form categories data English Language
        dispatch('fetchFormCategoriesEn').then(() => {
          // Fetching Form categories data Spanish Language
          dispatch('fetchFormCategoriesEs').then(() => {
            response('Form Categories fetched successfully.')
          })
        }, error => {
          reject(error.message)
        }, error => {
          reject(error.message)
        })
      })
     },
    fetchForms({ dispatch }) {
      return new Promise((response, reject) => {
        // Fetching Floors data English Language
        dispatch('fetchFormsEn').then(() => {
          // Fetching Floors data Spanish Language
          dispatch('fetchFormsEs').then(() => {
            response('Forms fetched successfully.')
          })
        }, error => {
          reject(error.message)
        }, error => {
          reject(error.message)
        })
      })
     },
    fetchFormPackets({ dispatch }) { 
      return new Promise((response, reject) => {
        // Fetching Form Packets data English Language
        dispatch('fetchFormPacketsEn').then(() => {
          // Fetching Form Packets data Spanish Language
          dispatch('fetchFormPacketsEs').then(() => {
            response('Form Packets fetched successfully.')
          })
        }, error => {
          reject(error.message)
        }, error => {
          reject(error.message)
        })
      })
    },

    // Data pull completed


    saveInCache({ state, getters }, path) {
      return new Promise((response, reject) => {
        state.fileCache = caches.open('fileCache')
          .then(cache => {
            cache.match(getters.getCMSlink + path)
              .then(cacheResponse => {
                if (cacheResponse) {
                  return (cacheResponse.blob())
                    .then(blob => {
                      response(URL.createObjectURL(blob))
                    })
                }
                else {
                  cache.add(getters.getCMSlink + path)
                  cache.match(getters.getCMSlink + path)
                    .then(cacheResponse => {
                      return (cacheResponse.blob())
                        .then(blob => {
                          response(URL.createObjectURL(blob))
                        })
                    })
                }
              })
          })
      })
    },
    searchOdysseybyName({ state }, keyword) {
      return new Promise((response, reject) => {
        fb.odysseyCollection
          .where("courtLocation", "==", "santafe")
          .where("dateCreated", "==", today())
          .where("partyOneName", "==", keyword.toLowerCase())
          .get()
          .then(querySnapshot => {
            if (querySnapshot.empty) reject('Sorry I did not find any results for this user name.')
            response(querySnapshot);
          })
      })
    },
    searchOdysseybyCaseNo({ state }, caseNumber) {
      return new Promise((response, reject) => {
        fb.odysseyCollection
          .where("courtLocation", "==", "santafe")
          .where("dateCreated", "==", today())
          .where("caseNo", "==", caseNumber)
          .get()
          .then(querySnapshot => {
            if (querySnapshot.empty) reject('Sorry I did not find any results for this case number.')
            response(querySnapshot);
          })

      })
    },
    searchByCaseNo({ state }, caseNumber) {
      return new Promise((response, reject) => {
        let config = {
          method: 'get',
          url: 'https://us-central1-connect-dashboard.cloudfunctions.net/apiV2/caseSearch/a763cd056f1b2405788443b7197e0708',
          params: {
            caseNumber: caseNumber,
            courtName: 'first judicial district santa fe county'
          }
        };

        axios(config)
          .then(result => {
            //   console.log('DATA X CHANGE: ', result.data)
            response(result.data);
          })
          .catch(function (error) {
            // console.log(error);
            reject(error)
          });

      })
    },
    searchByName({ state }, nameObject) {
      return new Promise((response, reject) => {
        let parameter;
        if (nameObject.middleName === '' || nameObject.middleName === null) {
          parameter = {
            firstName: (nameObject.firstName.toLowerCase()).trim(),
            lastName: (nameObject.lastName.toLowerCase()).trim(),
            courtName: 'first judicial district santa fe county'
          }
        }
        else {
          parameter = {
            firstName: (nameObject.firstName.toLowerCase()).trim(),
            lastName: (nameObject.lastName.toLowerCase()).trim(),
            middleName: (nameObject.middleName.toLowerCase()).trim(),
            courtName: 'first judicial district santa fe county'
          }
        }
        let config = {
          method: 'get',
          url: 'https://us-central1-connect-dashboard.cloudfunctions.net/apiV2/caseSearch/a763cd056f1b2405788443b7197e0708',
          params: parameter
        };

        axios(config)
          .then(result => {
            console.log('DATA X CHANGE: ', result.data)
            response(result.data);
          })
          .catch(function (error) {
            // console.log(error);
            reject(error)
          });

      })
    }
  }
}
