import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from './store'
Vue.use(VueI18n)

// Maps, SRL Guide, Forms, Form Packets are stored as a MAP, because of which it is pulled differently
let getMapsEn = () => {
  return store.getters.getMapsEn
}

let getMapsEs = () => {
  return store.getters.getMapsEs
}

let getFormsEn = () => {
  return store.getters.getFormsEn
}

let getFormsEs = () => {
  return store.getters.getFormsEs
}

let getFormPacketsEn = () => {
  return store.getters.getFormPacketsEn
}

let getFaqCategoryEn = () => {
  return store.getters.getFaqCategoriesEn
}

let getFaqCategoryEs = () => {
  return store.getters.getFaqCategoriesEs
}

let getFormCategoryEn = () => {
  return store.getters.getFormCategoryEn
}

let getFormCategoryEs = () => {
  return store.getters.getFormCategoryEs
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: {
    // English Data Fetch
    en: {
      // Homepage
      homepages: store.getters.getHomepageEn,

      // Faqs & SRL Guides
      faqCategory: getFaqCategoryEn,
      faqs: store.getters.getFaqsEn,
      
      // Maps
      maps: getMapsEn,
      floors: store.getters.getFloorsEn,

      // Forms
      formCategory: getFormCategoryEn,
      forms: getFormsEn,
      formPackets: getFormPacketsEn
    },
    es: {
      homepages: store.getters.getHomepageEs,

      // Faqs & SRL Guides
      faqCategory: getFaqCategoryEs,
      faqs: store.getters.getFaqsEs,
      
      // Maps
      maps: getMapsEs,
      floors: store.getters.getFloorsEs,

      // Forms
      formCategory: getFormCategoryEs,
      forms: getFormsEs,
      formPackets: getFormPacketsEn
    }
  }
})
